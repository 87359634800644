@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-recurring-booked {
  $breakpoint: 600px;

  &__section {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    background-color: light.$background-secondary-default;
    padding: spacing.$m;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$m;

    @media (min-width: $breakpoint) {
      margin-bottom: spacing.$xl;
    }
  }

  &__input {
    width: 100%;

    @media (min-width: $breakpoint) {
      max-width: 280px;
    }

    &--narrow {
      @media (min-width: $breakpoint) {
        max-width: 124px;
      }
    }
  }

  &__when {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$m;
  }
}
